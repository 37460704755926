import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import * as styles from '../styles'

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const { title, date, description } = frontmatter

  return (
    <Layout>
      <SEO title="Home" keywords={['gatsby', 'application', 'react']} />

      <styles.BlogPostContainer>
        <div className="blog-post-header">
          <h1 className="blog-post-title">
            {title}
            <span className="blog-post-date">Published {date}</span>
          </h1>
          <h2 className="blog-post-description">{description}</h2>
        </div>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </styles.BlogPostContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        tags
        date(formatString: "MMMM DD,  YYYY")
        description
      }
    }
  }
`
